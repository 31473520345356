import React from 'react'

const loanPage = () => {
    return (
        <div>
            <div style={{height: "100px", width: "100%", textAlign: "center", marginTop: "120px"}}>
            Da oggi puoi richiedere un finanziamento finalizzato all'acquisto di una delle 
            nostre vetture in pochissimi click.

            <br/>
            <br/>

            Segui la procedura sottostante per caricare in autonomia la richiesta di finanziamento 
            e ricevere l'esito in tempo reale!
                 
            </div>
            <iframe title="agos" src="https://finanziamenti.agosweb.it/cics/cwba/NPJ1O001/cduser=Vidmbkz&amp;cdconv=15712707&amp;cdcval=e&amp;imbene=0,00&amp;cdfnz=nvi&amp;cnvritiro=15712707&amp;cdazio=IN&amp;inpp=P&amp;dtrich=20170726&amp;cdsource=1" width="100%" height="1000"></iframe>
        </div>
    );
}

export default loanPage;